import React, {createContext} from "react";


export const GlobalVariableContext = createContext();

const variables ={
    statusReserved: 'Reservado',
    pickingStatePOS: 'pendiente de proceso en POS',
    locationCodeCenterOfDistribution: '68',
    nameCenterOfDistribution: 'Centro de distribución'

}

export const  GlobalVariableProvider = ({children}) =>
{
    return (
         <GlobalVariableContext.Provider value={{
             variables
         }}>
             {children}
         </GlobalVariableContext.Provider>
    );
}

