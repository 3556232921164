import React, {useState, useEffect} from "react";

import { Box,  Icon} from "@mui/material";
import {BrowserBarcodeReader} from "@zxing/library";
import {useDispatch, useSelector} from "../../redux/store";
import {startCamera, stopCamera} from "../../redux/slices/camera";
import {formatData} from "../../utils/functions";
import axios from "../../utils/axios";
import {MIconButton} from "../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import useAuth from "../../hooks/useAuth";
import {useSnackbar} from "notistack";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {


    const dispatch = useDispatch();

    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const {camera} = useSelector((state) => {
        return state.camera;
    });
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {user} = useAuth();


    useEffect(() => {
        initCamera();
        if (camera != null) {
            camera
                .getVideoInputDevices()
                .then(videoInputDevices => {
                    setupDevices(videoInputDevices);
                })
                .catch(err => {
                    console.error(err);
                });
        }

        //eslint-disable-next-line
    }, []);

    const initCamera = async () => {
        if (camera != null) {
            await dispatch(startCamera(camera));
        } else {
            await dispatch(startCamera(new BrowserBarcodeReader()));
        }
    }

    useEffect(
        deviceId => {
            decodeContinuously(deviceId);
        },
        [decodeContinuously, selectedDeviceId]
    );


    function setupDevices(videoInputDevices) {
        // selects first device
        let hasBackCamera = false;
        for (const device of videoInputDevices) {
            if (/back|rear|environment/gi.test(device.label)) {
                setSelectedDeviceId(device.deviceId);
                hasBackCamera = true;
                break;
            }
        }
        if (!hasBackCamera) {
            setSelectedDeviceId(videoInputDevices[0].deviceId);
        }

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    function decodeContinuously(selectedDeviceId) {
        if (camera != null) {
            camera.decodeOnceFromVideoDevice(
                selectedDeviceId,
                "video").then(async (r) => {
                if (r) {
                    await dispatch(stopCamera());
                    props.setLoading(true);
                    let filtros = [
                        {
                            name: "rut_user",
                            data: user.userRut
                        },
                        {
                            name: "store_id",
                            data: user.location.idKomax + ""
                        }
                    ];

                    try {
                        const filtrosResponse = formatData(filtros);
                        const productResponse = await axios.get('querystock/productByBarCode/' + r.text + filtrosResponse);
                        props.setProduct(productResponse.data.data);
                        props.setRenderValue(2);
                        props.setLoading(false);
                    } catch (e) {
                        props.setLoading(false);
                        if (e.response.status === 404) {
                            enqueueSnackbar("Producto no encontrado", {
                                variant: "error",
                                action: (key) => (
                                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                        <Icon icon={closeFill}/>
                                    </MIconButton>
                                ),
                            });

                        } else {
                            enqueueSnackbar("Error en la búsqueda de producto", {
                                variant: "error",
                                action: (key) => (
                                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                        <Icon icon={closeFill}/>
                                    </MIconButton>
                                ),
                            });
                        }
                        setTimeout(async ()=>{
                            await dispatch(startCamera(camera));
                        },2000)

                    }
                }

            }).catch(e => {
                console.error(e);
            });
        }
    }


    return (
        <Box width={'100%'} height={'100%'}>
            <video id="video" width="100%" height={300} style={{'marginBottom': '20px'}}/>
        </Box>
    );
}
