import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
// material
import {alpha, styled} from "@mui/material/styles";
import {
    Box,
    CardActionArea,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
// components
import Logo from "../../components/Logo";
import MyAvatar from "../../components/MyAvatar";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import {MHidden} from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import SvgIconStyle from "../../components/SvgIconStyle";
import {LoadingButton} from "@mui/lab";
import DialogStoresBrands from "../../components/authentication/modal/DialogStoresBrands";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled("div")(({theme}) => ({
    [theme.breakpoints.up("lg")]: {
        flexShrink: 0,
        transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.complex,
        }),
    },
}));

const AccountStyle = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12],
}));

const ListItemStyle = styled(ListItemButton)(({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&:before": {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: "none",
        position: "absolute",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
    onToggleCollapse: PropTypes.func,
    collapseClick: PropTypes.bool,
};

function IconCollapse({onToggleCollapse, collapseClick}) {

    return (
        <Tooltip title="Mini Menu">
            <CardActionArea
                onClick={onToggleCollapse}
                sx={{
                    width: 18,
                    height: 18,
                    display: "flex",
                    cursor: "pointer",
                    borderRadius: "50%",
                    alignItems: "center",
                    color: "text.primary",
                    justifyContent: "center",
                    border: "solid 1px currentColor",
                    ...(collapseClick && {
                        borderWidth: 2,
                    }),
                }}
            >
                <Box
                    sx={{
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "currentColor",
                        transition: (theme) => theme.transitions.create("all"),
                        ...(collapseClick && {
                            width: 0,
                            height: 0,
                        }),
                    }}
                />
            </CardActionArea>
        </Tooltip>
    );
}

DashboardSidebar.propTypes = {
    isOpenSidebar: PropTypes.bool,
    onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}) {
    const {pathname} = useLocation();
    const {user, logout} = useAuth();
    const [open, setOpen] = useState(false);
    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onHoverEnter,
        onHoverLeave,
    } = useCollapseDrawer();

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);



    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column",
                },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: "center",
                    }),
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box component={RouterLink} to="/" sx={{display: "inline-flex"}}>
                        <Logo/>
                    </Box>
                </Stack>

                {isCollapse ? (
                    <MyAvatar sx={{mx: "auto", mb: 2}}/>
                ) : (
                    <AccountStyle>
                        <MyAvatar/>
                        <Box sx={{ml: 2}}>
                            <Typography variant="subtitle2" sx={{color: "text.primary"}}>
                                {user?.displayName}
                            </Typography>
                            <Typography variant="body2" sx={{color: "text.secondary"}}>
                                {user?.role}
                            </Typography>
                        </Box>
                    </AccountStyle>
                )}
                <LoadingButton
                    sx={{boxShadow: 'none'}}
                    size="medium"
                    onClick={()=>{
                        setOpen(true);
                    }}
                    color={'primary'}
                    variant="contained">
                    Cambiar tienda
                </LoadingButton>
            </Stack>

            <Box>

            </Box>
            <NavSection navConfig={sidebarConfig} isShow={!isCollapse}/>

            <Box sx={{flexGrow: 1}}/>

            {!isCollapse && (
                <List disablePadding>
                    <ListItemStyle onClick={() => logout()}>
                        <ListItemIconStyle>
                            <SvgIconStyle
                                src={`/static/icons/navbar/ic_reply.svg`}
                                sx={{width: "100%", height: "100%"}}
                            />
                        </ListItemIconStyle>
                        <ListItemText disableTypography primary={"Cerrar sesión"}/>
                    </ListItemStyle>
                </List>
            )}
        </Scrollbar>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                },
                ...(collapseClick && {
                    position: "absolute",
                }),
            }}
        >
            <MHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: {width: DRAWER_WIDTH},
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
            <DialogStoresBrands open={open} setOpen={setOpen} isFirstLogin={false} ></DialogStoresBrands>

            <MHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: "background.default",
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: "blur(6px)",
                                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) =>
                                    alpha(theme.palette.background.default, 0.88),
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
}
