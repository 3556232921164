import React, {useEffect, useState} from 'react';
import LoadingScreenPages from "../../components/LoadingScreenPages";
import Page from "../../components/Page";
import QueryBarCodeStock from "../../components/query-stock/QueryBarCodeStock";
import {Box, Card} from "@mui/material";
import DetailQueryStock from "../../components/query-stock/DetailQueryStock";

const QueryStock = () => {
    const [loading, setLoading] = useState(false);
    const [hideTable, setHideTable] = useState(true);
    const [product, setProduct] = useState(null);

    const [renderValue, setRenderValue] = useState(1);
    useEffect(() =>{
        setRenderValue(1);
    }, [])
    return (
        <div>
            <Page title="Consulta de stock" id="pageId">
                <LoadingScreenPages loading={loading}/>
                <Box sx={{p: 2}}>
                    <Card>
                        {renderValue === 1? <QueryBarCodeStock setRenderValue={setRenderValue} setLoading={setLoading}
                                                               setProduct={setProduct} product={product}/>
                        : <DetailQueryStock product={product} setProduct={setProduct} hideTable={hideTable} setHideTable={setHideTable} setRenderValue={setRenderValue} setLoading={setLoading}/>}

                    </Card>
                </Box>

            </Page>
        </div>
    );
};

export default QueryStock;
