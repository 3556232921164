import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import ordersReducer from './slices/orders';
import cameraReducer from './slices/camera';


// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: []
};

const rootReducer = combineReducers({
    product:  productReducer,
    orders: ordersReducer,
    camera: cameraReducer
});

export { rootPersistConfig, rootReducer };
