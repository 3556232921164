import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import {getOrdersBy} from "../redux/slices/orders";
import useAuth from "../hooks/useAuth";
import {useDispatch} from "../redux/store";

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadiusSm,
    "& fieldset": {
        border: "none",
    },
}));



function SearchBar({search, setSearch, setLoading, setExpandedId}) {
    const onChangeSearch = (value) =>{
        setSearch(value);
    }
    const dispatch = useDispatch();

    const { user } = useAuth();

    const handleSearch = async ()=>{
        setLoading(true);
        setExpandedId(-1);
        let obj = [
            {
                name: "rut_user",
                data: user.userRut,
            },
            {
                name: "search_filter",
                data: search,
            }
        ];
        await dispatch(getOrdersBy(user.location.idKomax,obj));

        setLoading(false);
    }
    return (
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <TextFieldStyled
                label="Ingresar número de orden"
                id="outlined-start-adornment"
                type='tel'
                fullWidth
                value={search}
                onChange={(e) => {onChangeSearch(e.target.value.replace(/[^0-9]/g, ''))
                }}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label="Buscar" onClick={handleSearch}>
                                <SearchIcon color="primary" />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
}

export default SearchBar;