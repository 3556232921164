import React, { useState, useEffect } from "react";

import {Alert, Box, Button, Stack} from "@mui/material";
import {useDispatch, useSelector} from "../../redux/store";
import {startCamera, stopCamera} from "../../redux/slices/camera";

// eslint-disable-next-line import/no-anonymous-default-export
export default function(props) {



    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const dispatch = useDispatch();

    const {camera} = useSelector((state) => {
        return state.camera;
    });




    useEffect(() => {
/*
        initCamera();
*/
        if(camera != null)
        {
            camera
                .getVideoInputDevices()
                .then(videoInputDevices => {
                    setupDevices(videoInputDevices);
                })
                .catch(err => {
                    console.error(err);
                });
        }
        //eslint-disable-next-line
    },[]);

    useEffect(
        deviceId => {
                decodeContinuously(deviceId);
        },
        [decodeContinuously, selectedDeviceId]
    );

    const resetCamera = async () =>{
        props.setDisplayAlertTab1('none');
        props.setInfoAlertTab2("error");
        props.setMessageAlertTab2("Código de barra incorrecto");
        await dispatch(stopCamera())
        await dispatch(startCamera(camera));
        camera
            .getVideoInputDevices()
            .then(videoInputDevices => {
                setupDevices(videoInputDevices);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function setupDevices(videoInputDevices) {
        // selects first device

        let hasBackCamera = false;
            for (const device of videoInputDevices) {
                if (/back|rear|environment/gi.test(device.label)) {
                    setSelectedDeviceId(device.deviceId);
                    hasBackCamera = true;
                    break;
                }
            }
            if(!hasBackCamera)
            {
                setSelectedDeviceId(videoInputDevices[0].deviceId);
            }

    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
    function decodeContinuously(selectedDeviceId) {
        if(camera != null)
        {
            camera.decodeOnceFromVideoDevice(
                selectedDeviceId,
                "video").then(r => {
                if (r) {
                    // properly decoded qr code
                    const codes= props.product.codigos_barra;
                    let isCorrect = false;
                    if(codes != null)
                    {
                        for (let i = 0; i < codes.length; i++) {
                            if(codes[i] === r.text)
                            {
                                isCorrect = true;
                                break;
                            }
                        }
                    }
                    if(isCorrect)
                    {
                        props.setDisplayAlertTab1("flex");
                        props.setInfoAlertTab2("success");
                        props.setMessageAlertTab2("Código correcto")
                    }
                    else{
                        props.setDisplayAlertTab1("flex");
                        props.setInfoAlertTab2("error");
                        props.setMessageAlertTab2("Código de barra incorrecto")
                    }
                }
            }).catch(e =>{
                console.error(e);
            });
        }

    }



    return (
        <Box width={'100%'} height={'100%'}>
            <video id="video" width="100%" height={300} style={{'marginBottom':'20px'}} />
            <Alert severity={props.infoAlertTab2} sx={{display: props.displayAlertTab1, mb: 3}}>{props.messageAlertTab2}</Alert>
            <Stack direction="row" justifyContent={"space-around"} alignItems={"center"} spacing={2} sx={{position: 'absolute', bottom: 13, width: 'calc(100% - 48px)'}}>
                <Button sx={{width: '50px', minWidth: '115px', display: (props.infoAlertTab2 === "success" || props.displayAlertTab1 === 'none' ? "none":  'flex')}} color={'secondary'} onClick={resetCamera} variant="contained">Reintentar</Button>
                <Button sx={{width: '50px', minWidth: '115px'}} onClick={props.handleChangeProductState}  disabled={props.infoAlertTab2 !== 'success'} variant="contained">Reservar</Button>
            </Stack>
        </Box>
    );
}
