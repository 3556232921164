// material
import {styled} from "@mui/material/styles";
import {Box, Container, Stack, Typography} from "@mui/material";
// components
import Page from "../../components/Page";
import {LoginForm} from "../../components/authentication/login";
import {useEffect, useState} from "react";
/*
import useAuth from "../../hooks/useAuth";
*/
import {useDispatch, useSelector} from "../../redux/store";
import {stopCamera} from "../../redux/slices/camera";
import DialogStoresBrands from "../../components/authentication/modal/DialogStoresBrands";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
    [theme.breakpoints.up("md")]: {
        display: "flex",
    },
}));

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(12, 0),
}));


// ----------------------------------------------------------------------

export default function Login() {



    const [open, setOpen] = useState(false);
/*
    const [location, setLocation] = useState('');
*/
/*    const {user} = useAuth();*/

    const dispatch = useDispatch();

    const {camera} = useSelector((state) => {
        return state.camera;
    });

    useEffect(() => {
        initial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  /*  useEffect(() =>{
        if(user != null)
        {
            setLocation(user.userPreferredLocation)
        }
    },[user])*/

    const initial = async () => {
        if (camera != null) {
            await dispatch(stopCamera())
        }
    }


    return (
        <RootStyle title="Iniciar Sesión">
            <Container maxWidth="sm">
                <ContentStyle>
                    <Box component="img" src="/static/img/AXO_logo_negro.png"
                         sx={{width: "50%", marginLeft: "auto", marginRight: "auto", mb: 1}}>
                    </Box>
                    <Stack sx={{mb: 3}}>
                        <Typography variant="h6" align="center">
                            APLICACIÓN DE PICKING INTELIGENTE
                        </Typography>
                    </Stack>

                    <LoginForm handleDialogOpen={setOpen}/>
                    <Box
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{p: 2}}
                    >
                    </Box>
                </ContentStyle>
                <DialogStoresBrands open={open} setOpen={setOpen} isFirstLogin={true}></DialogStoresBrands>
            </Container>
        </RootStyle>
    );
}
