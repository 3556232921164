// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  queued_orders: getIcon('box-time'),
  stock_query: getIcon('box-search'),
  create_store_order: getIcon('truck-fast'),
  refounds: getIcon('box-rotate'),
  settings: getIcon('settings'),
  payments: getIcon('wallet'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Órdenes',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.queued_orders
      },
      {
        title: 'Consulta de stock',
        path: PATH_DASHBOARD.general.queryStock,
        icon: ICONS.stock_query
      },
      /*
      {
        title: 'Crear orden',
        path: PATH_DASHBOARD.general.queuedOrders,
        icon: ICONS.create_store_order
      },
      {
        title: 'Devoluciones',
        path: PATH_DASHBOARD.general.queuedOrders,
        icon: ICONS.refounds
      },
      {
        title: 'Configuración',
        path: PATH_DASHBOARD.general.queuedOrders,
        icon: ICONS.settings
      },
      {
        title: 'Pagos',
        path: PATH_DASHBOARD.general.queuedOrders,
        icon: ICONS.payments
      },*/
    ]
  },
];

export default sidebarConfig;
