import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
// utils
// import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  orders: [],
};

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    // GET ORDERS
    getOrdersBySuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    updateOrdersInMemoryBySuccess(state,action){
      state.orders = action.payload;
    }
  },
});

// Reducer
export default slice.reducer;

const formatData = (filtros) => {
  let string = "";
  filtros.forEach((element) => {
    if (element.data) {
      let simbol = string === "" ? "?" : "&";
      string = string + simbol + element.name + "=" + element.data;
    }
  });
  return string;
};


// ----------------------------------------------------------------------
export function updateOrdersInMemory(orders){
  return async (dispatch) =>{
    try{
      dispatch(slice.actions.updateOrdersInMemoryBySuccess(orders));
    }
    catch (e) {
      dispatch(slice.actions.hasError(e));
    }
  }
}

export function getOrdersBy(idStore,filtros) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let filtro = formatData(filtros);
      const response = await axios.get("/orders/nominateOrdersByIdStore/" +idStore.toString() + filtro);
      localStorage.setItem('filters', JSON.stringify(filtros));
      if (response.status === 200) {
        dispatch(slice.actions.getOrdersBySuccess(response.data));
      }
        else if(response.status === 404 ) {
        dispatch(slice.actions.getOrdersBySuccess(response.data));

      } else {
        dispatch(slice.actions.getOrdersBySuccess([]));
      }
    } catch (error) {
      if(error.response.status === 404 && error.response.data.message === 'Orders list without data')
      {
        let obj = {
          data: {
            totalExpressOrders: 0,
            totalNormalOrders: 0,
            orderList: [],
            mensaje: 'Te encuentras al día!'
          }
        }
        dispatch(slice.actions.getOrdersBySuccess(obj));
      }
      else if(error.response.status === 404 && error.response.data.message === 'Order list filter without data')
      {
        let obj = {
          data: {
            totalExpressOrders: 0,
            totalNormalOrders: 0,
            orderList: [],
            mensaje: 'No existen órdenes para el criterio de búsqueda'
          }
        }
        dispatch(slice.actions.getOrdersBySuccess(obj));
      }
      else{
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}
