import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
// utils
import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import jwtDecode from "jwt-decode";

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  preUser: null
};



const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  PRELOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user: user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  prelogin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};



function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const refreshToken = window.localStorage.getItem("refreshToken");
        const location = window.localStorage.getItem("location");

        if (accessToken && isValidToken(accessToken)) {
          let locationSaved = JSON.parse(location);
          setSession(accessToken, refreshToken,locationSaved);
          const descodeToken = jwtDecode(accessToken);

          const user = {
            id: descodeToken.user_id,
            displayName: descodeToken.user_fullname,
            userRut: descodeToken.rut_user,
            location: locationSaved,
            acccessToken: accessToken,
            refreshToken: refreshToken
          };

          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        }
        else if(refreshToken && isValidToken(refreshToken))
        {

          axios.post("/auth/refreshToken", {
            "refresh_token": refreshToken,
          },{
            auth:{
              username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
              password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
            },
          }).then((rs) => {
            const accessToken = rs.data.access_token;
            let locationSaved = JSON.parse(location);
            const descodeToken = jwtDecode(accessToken);

            setSession(accessToken, rs.data.refresh_token,locationSaved);

            const user = {
              id: descodeToken.user_id,
              displayName: descodeToken.user_fullname,
              userRut: descodeToken.rut_user,
              location: locationSaved,
              acccessToken: accessToken,
              refreshToken: refreshToken
            };

            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user,
              },
            });

          }).catch((error) => {
            console.error(error);
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
              }
          )
        }
        else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (user,location) => {
    user.location = location[0];
    setSession(user.acccessToken,user.refreshToken, user.location);
    dispatch({
      type: "LOGIN",
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  const prelogin =  async (email,password) =>
  {
    const response = await axios.post("/auth/getToken", {
      "userId": email,
      "userPassword": password,
    },
        {
          auth:{
            username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
            password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
          },
        });

    const { access_token, refresh_token, user_id, user_fullname } = response.data;
    const userJwtDecode = jwtDecode(access_token);
    const user = {
      id: user_id,
      displayName: user_fullname,
      userRut: email,
      location: null,
      userPreferredLocation: userJwtDecode.userPreferredLocation,
      acccessToken: access_token,
      refreshToken: refresh_token
    };
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;

    dispatch({
      type: "PRELOGIN",
      payload: {
        user,
      },
    });
  }
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null, null, null);
    dispatch({ type: "LOGOUT" });
  };

  const resetPassword = () => {};

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        login,
        logout,
        prelogin,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
