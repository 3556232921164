import React from 'react';
import CardContent from "@mui/material/CardContent";
import {Alert, AlertTitle, Box, Grid, Skeleton, Stack} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {LoadingButton} from "@mui/lab";
import {useTheme} from "@mui/material/styles";
import {BrowserBarcodeReader} from "@zxing/library";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {startCamera} from "../../redux/slices/camera";
import {useDispatch, useSelector} from "../../redux/store";
import useGlobVars from "../../hooks/useGlobalVars";

function OrderDetail({setCodeReader,setOpenModalBarCode,errorDetail,products, setProduct, setValue}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {camera} = useSelector((state) => {
        return state.camera;
    });
    const {variables} =useGlobVars();
    const handleOpenModalBarCode = async (product) => {
        setProduct(product);
        if(camera != null){
            await dispatch(startCamera(camera));
        }
        else{
            await dispatch(startCamera(new BrowserBarcodeReader()));
        }
        setOpenModalBarCode(true);
    }
    return (
        <CardContent>
            <Grid container spacing={{xs: 2, md: 2}} columns={{xs: 2, sm: 2, md: 8}}>
                {products == null ?
                    errorDetail ?
                        <Alert severity="error" sx={{width: '100%', marginLeft: '8px'}}>
                            <AlertTitle>Error</AlertTitle>
                            Ha ocurrido un error al cargar — <strong>detalle orden</strong>
                        </Alert>
                        :
                    ([1,2].map(e => (
                    <Grid item xs={2} sm={4} md={4} key={e}>
                                <Skeleton animation="wave" variant="rectangular" sx={{borderRadius: theme.shape.borderRadiusMd+"px"}} width={'100%'} height={118} />
                    </Grid>
                ))) : products.map((product,index )=>
                    <Grid item xs={2} sm={4} md={4} key={index}>
                        <Card sx={{backgroundColor: '#F6F6F6', p: theme.spacing(1)}}>
                            <Stack direction="row" spacing={2}>
                                <Box  sx={{
                                    height: 165,
                                    width: 120,
                                    maxHeight: {xs: 120, md: 120},
                                    maxWidth: {xs: 90, md: 120},
                                    minWidth: {xs: 90, md: 120},

                                }}>
                                    <LazyLoadImage
                                        height= {'100%'}
                                        width= {'100%'}
                                        src={product.imagen == null ||  product.imagen === ""  ? '/static/img/producto-no-encontrado.jfif'  : product.imagen}
                                    />
                                </Box>

                                <Box>
                                    <Typography variant="subtitle1">{product.nombre_producto}</Typography>
                                    <Typography sx={{display: 'block'}} variant="caption">Marca: {product.marca_producto}</Typography>
                                    <Typography sx={{display: 'block'}} variant="caption">SKU: {product.sku_producto}</Typography>
                                    <Typography sx={{display: 'block'}} variant="caption">Talla: {product.talla}</Typography>
                                    <Typography sx={{display: 'block'}} variant="caption">Cantidad a reservar: {product.cantidad_pedido}</Typography>
                                    <Typography sx={{display: 'block', fontWeight: 'bold'}} variant="caption">Stock disponible: {product.stock_ubicacion}</Typography>


                                </Box>
                            </Stack>
                            <Stack direction="row" spacing={2} pt={2}
                                   justifyContent={"space-around"}>
                                <LoadingButton
                                    size="medium"
                                    color={'primary'}
                                    disabled={product.StatusAPI === variables.statusReserved }
                                    onClick={() => handleOpenModalBarCode(product)}
                                    variant="contained">

                                    {product.StatusAPI === variables.statusReserved ? 'Reservado': 'Reservar Producto'}
                                </LoadingButton>

                            </Stack>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </CardContent>
    );
}

export default OrderDetail;