// material
import { Box } from "@mui/material";
// components
import LoadingScreen from "./LoadingScreen";

export default function LoadingScreenPages({ loading }) {
  return loading ? (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "transparent",
        height: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        position: "fixed !important",
        zIndex: "2000 !important",
        cursor: "progress",
          "-webkit-transform": 'translateZ(10px)'
      }}
    >
      <Box
        sx={{
          minHeight: "100vh",
          minWidth: "100%",
          display: "flex !important",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      >
        <LoadingScreen />
      </Box>
    </Box>
  ) : (
    false
  );
}
