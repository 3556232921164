import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogContent, DialogTitle, Icon, IconButton, InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField
} from "@mui/material";
import Zxing from "../../bar-code-scanner/Zxing";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {useDispatch, useSelector} from "../../../redux/store";
import {startCamera, stopCamera} from "../../../redux/slices/camera";
import axios from "../../../utils/axios";
import {MIconButton} from "../../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import {useSnackbar} from "notistack";
import cloneDeep from "lodash/cloneDeep";
import CloseIcon from "@mui/icons-material/Close";
import {BrowserBarcodeReader} from "@zxing/library";
import useAuth from "../../../hooks/useAuth";
import useGlobVars from "../../../hooks/useGlobalVars";
import {updateOrdersInMemory} from "../../../redux/slices/orders";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            pt={2}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            height={'100%'}

            {...other}
        >
            {value === index && (<>{children}</>
            )}
        </Box>
    );
}

const TextFieldStyled = styled(TextField)(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadiusSm,
    "& fieldset": {
        border: "none",
    },
}));

const BootstrapDialogTitle = (props) => {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle color={'primary'} sx={{m: 0, p: 2, paddingLeft: '24px'}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const DialogOrderBarCode = ({
                                openModalBarCode,
                                setOpenModalBarCode,
                                setBarCode,
                                barCode, product,
                                setLoading,
                                setProducts,
                                products,
                                value,
                                setValue,
                                orderSelected,
                            }) => {

    const [displayAlert, setDisplayAlert] = useState("none");
    const [infoAlert, setInfoAlert] = useState("error");
    const [messageAlert, setMessageAlert] = useState("Código de barra incorrecto");
    const [displayAlertTab1, setDisplayAlertTab1] = useState("none");
    const [infoAlertTab2, setInfoAlertTab2] = useState("error");
    const [messageAlertTab2, setMessageAlertTab2] = useState("Código de barra incorrecto");
    const [displayTab1, setDisplayTab1] = useState("flex");
    const [disabledSearchInput, setDisabledSearchInput] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {user} = useAuth();
    const {variables} = useGlobVars();
    const {orders} = useSelector((state) => {
        return state.orders
    });

    const {camera} = useSelector((state) => {
        return state.camera;
    });

    useEffect(() => {
        evaluateCamera();
        //eslint-disable-next-line
    }, [value])

    const evaluateCamera = async () => {
        if (value === 0) {
            if (camera != null) {
                await dispatch(startCamera(camera));
            } else {
                await dispatch(startCamera(new BrowserBarcodeReader()));
            }
        } else {
            await dispatch(stopCamera());
        }
    }
    const handleChange = async (event, newValue) => {
        if (newValue === 0) {
            setDisplayTab1("flex")
        } else {
            setDisplayTab1("none")
        }
        setBarCode("");
        setDisplayAlert('none');
        setInfoAlert('error');
        setMessageAlert('Código de barra incorrecto');
        setDisplayAlertTab1("none");
        setInfoAlertTab2("error");
        setMessageAlertTab2("Código de barra incorrecto");
        setValue(newValue);
        setDisabledSearchInput(false);
    };

    const onChangeBarCode = (value) => {
        setBarCode(value)
    }
    const searchBarCode = () => {
        const codes = product.codigos_barra;
        let isCorrect = false;
        if (codes != null) {
            for (let i = 0; i < codes.length; i++) {
                if (codes[i] === barCode) {
                    isCorrect = true;

                    break;
                }
            }
        }
        if (isCorrect) {
            setDisabledSearchInput(true);
            setDisplayAlert("flex");
            setInfoAlert("success");
            setMessageAlert("Código correcto")
        } else {
            setDisplayAlert("flex");
            setInfoAlert("error");
            setMessageAlert("Código de barra incorrecto")
        }
    }
    const resetStates = () => {
        try {
            setBarCode("");
            setDisplayAlert('none');
            setInfoAlert('error');
            setMessageAlert('Código de barra incorrecto');
            setDisplayAlertTab1("none");
            setInfoAlertTab2("error");
            setMessageAlertTab2("Código de barra incorrecto");
            setDisabledSearchInput(false);
        } catch (e) {
        }
        setOpenModalBarCode(false);

    }

    const handleChangeProductState = async () => {
        setLoading(true);
        const bookingPost = {
            "IdStore": user.location.idKomax,
            "OrderId": orderSelected.orderNumber,
            "sku": product.sku_producto,
            "Status": variables.statusReserved
        }
        try {
            const response = await axios.put("/booking/productReservationUpdate", bookingPost);
            if (response.status === 200) {
                setOpenModalBarCode(false);
                let newProducts = cloneDeep(products);
                const indexProduct = newProducts.findIndex((p) => p.sku_producto === product.sku_producto);
                if (indexProduct >= 0) {
                    newProducts[indexProduct].StatusAPI = variables.statusReserved;
                    setProducts(newProducts);
                }
                const allReserved = (product) => product.StatusAPI === variables.statusReserved;
                if (newProducts.every(allReserved)) {
                    let newOrderSelected = cloneDeep(orderSelected);
                    newOrderSelected.pickingState = variables.pickingStatePOS;
                    let newOrders = cloneDeep(orders);
                    const findIndex = newOrders.data.orderList.findIndex((order)=> order.orderNumber === newOrderSelected.orderNumber);
                    newOrders.data.orderList[findIndex] = newOrderSelected;
                    await dispatch(updateOrdersInMemory(newOrders));
                }
                resetStates();
                enqueueSnackbar(`Producto reservado!`, {
                    variant: "success",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill}/>
                        </MIconButton>
                    ),
                });
            }
        } catch (e) {
            setOpenModalBarCode(false);
            resetStates();
            enqueueSnackbar(`Error al reservar producto`, {
                variant: "error",
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                    </MIconButton>
                ),
            });
        }
        setLoading(false);
    }

    return (
        <Dialog sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root.MuiDialog-paper": {
                    minHeight: 590,
                    minWidth: 600,
                    [theme.breakpoints.down('sm')]: {
                        minWidth: 'auto',
                    },
                },
            },
        }} open={openModalBarCode} onClose={resetStates}>
            <BootstrapDialogTitle id="customized-dialog-title" onClose={resetStates}>
                Reservar Producto
            </BootstrapDialogTitle>
            <DialogContent sx={{display: 'flex', flexDirection: 'column'}}>
                <Box sx={{height: '100%', flex: '1 1 100%', display: 'flex', flexDirection: 'column'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example">
                        <Tab label="Escáner"/>
                        <Tab label="Ingreso Manual"/>
                    </Tabs>
                    <TabPanel value={value} index={0} dir={theme.direction} sx={{
                        height: '100%',
                        minHeight: '100%',
                        flex: '1 1 100%',
                        display: displayTab1,
                        flexDirection: 'column'
                    }}>
                        <Typography textAlign={'center'} pb={2}>Enfoque el código de barra del producto</Typography>
                        <Zxing product={product}
                               handleChangeProductState={handleChangeProductState}
                               displayAlertTab1={displayAlertTab1} setDisplayAlertTab1={setDisplayAlertTab1}
                               infoAlertTab2={infoAlertTab2} setInfoAlertTab2={setInfoAlertTab2}
                               messageAlertTab2={messageAlertTab2} setMessageAlertTab2={setMessageAlertTab2}
                        />


                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction} sx={{
                        height: '100%',
                        minHeight: '100%',
                        flex: '1 1 100%',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Stack spacing={1}>
                            <Typography textAlign={'center'} pb={2}>Ingrese el código de barra del producto</Typography>
                            <TextFieldStyled
                                label="Código de barra"
                                id="outlined-start-adornment"
                                type='tel'
                                fullWidth
                                disabled={disabledSearchInput}
                                value={barCode}
                                onChange={(e) => {
                                    onChangeBarCode(e.target.value.replace(/[^0-9]/g, ''))
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter')
                                        searchBarCode();
                                }}
                                inputProps={{
                                    maxLength: 13,
                                }}
                                sx={{pb: '12px'}}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="Buscar" onClick={searchBarCode}>
                                                <SearchIcon color="primary"/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Alert severity={infoAlert} sx={{display: displayAlert}}>{messageAlert}</Alert>

                            <Stack direction="row" justifyContent={"space-around"}
                                   alignItems={"center"} spacing={2}
                                   sx={{position: 'absolute', bottom: 24, width: 'calc(100% - 48px)'}}>
                                <Button sx={{width: '50px', minWidth: '100px'}}  onClick={handleChangeProductState} disabled={infoAlert !== 'success'}
                                        variant="contained">Reservar</Button>
                            </Stack>
                        </Stack>

                    </TabPanel>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default DialogOrderBarCode;