import * as Yup from "yup";
import {useState} from "react";
import {Form, FormikProvider, useFormik} from "formik";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {Alert, Box, IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
// hooks
import useAuth from "../../../hooks/useAuth";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
//

// ----------------------------------------------------------------------

export default function LoginForm({handleDialogOpen}) {
    const {prelogin} = useAuth();
    const isMountedRef = useIsMountedRef();
    const [showPassword, setShowPassword] = useState(false);



    const LoginSchema = Yup.object().shape({
        identifier: Yup.string()
            .required("Identificador es requerido"),
        password: Yup.string().required("Contraseña es requerida"),
    });

    const formik = useFormik({
        initialValues: {
            identifier: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: async (values, {setErrors, setSubmitting, resetForm}) => {
            try {
                await prelogin(values.identifier, values.password);
                handleDialogOpen(true);
            } catch (error) {
                console.error(error);
                resetForm();
                if (isMountedRef.current) {
                    setSubmitting(false);
                    setErrors({afterSubmit: "Credenciales inválidas"});
                }
            }
        },
    });

    const {errors, touched,  isSubmitting, handleSubmit, getFieldProps} = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    /*const validateRut = (rut) => {
        try {
            let valor = rut.replaceAll('.','');
            // Despejar Guión
            valor = valor.replaceAll('-','');

            // Aislar Cuerpo y Dígito Verificador
            let cuerpo = valor.slice(0,-1);
            let dv = valor.slice(-1).toUpperCase();

            // Formatear RUN
            rut = cuerpo + '-'+ dv

            // Si no cumple con el mínimo ej. (n.nnn.nnn)
            if(cuerpo.length < 7) {
                return false;
            }

            // Calcular Dígito Verificador
            let suma = 0;
            let multiplo = 2;

            // Para cada dígito del Cuerpo
            let i;
            for(i=1;i<=cuerpo.length;i++) {

                // Obtener su Producto con el Múltiplo Correspondiente
                let index;
                index = multiplo * valor.charAt(cuerpo.length - i);

                // Sumar al Contador General
                suma = suma + index;

                // Consolidar Múltiplo dentro del rango [2,7]
                if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }

            }

            // Calcular Dígito Verificador en base al Módulo 11
            let dvEsperado;
            dvEsperado = 11 - (suma % 11);

            // Casos Especiales (0 y K)
            dv = (dv == 'K')?10:dv;
            dv = (dv == 0)?11:dv;

            // Validar que el Cuerpo coincide con su Dígito Verificador
            let b = dvEsperado == dv;
            return b;
        }
        catch (e)
        {
            return false;
        }
    }*/

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {errors.afterSubmit && (
                        <Alert severity="error">{errors.afterSubmit}</Alert>
                    )}

                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="tel"
                        label="ID XSTORE"
                        {...getFieldProps("identifier")}
                        inputProps={{ maxLength: 10 }}
                        error={Boolean(touched.identifier && errors.identifier)}
                        helperText={touched.identifier && errors.identifier}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? "text" : "password"}
                        label="CONTRASEÑA"
                        {...getFieldProps("password")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Box
                    direction="row"
                    alignItems="center"
                    display={'flex'}
                    justifyContent="space-evenly"
                    sx={{p: 2}}
                >
                </Box>

                <LoadingButton
                    fullWidth
                    size="larger"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Iniciar sesión
                </LoadingButton>
            </Form>
        </FormikProvider>

    );
}
