import React from 'react';
import {Container, Stack} from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {alpha, styled} from "@mui/material/styles";
import {useSelector} from "../../redux/store";

const StatusDotDetail = styled("span")(() => ({
    width: 5,
    display: 'block',
    position: 'absolute',
    top: 8,
    height: 19,
    left: 1,
    borderRadius: 6
}));

const DRAWER_WIDTH = 280;

const OrderTotalStyle = styled("div")(({ theme }) => ({
    position: "fixed",
    width: "100%",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.grey["200"], 0.6),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 1000,
    bottom: 33,
    [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));
const OrderTotal = (props) => {
    const {orders} = useSelector((state) => {
        return state.orders
    });

    return (

        <Card>
            {orders == null || orders.length === 0 || orders.data === null || orders.data.orderList.length === 0 ?
               false
                :
                <OrderTotalStyle>
                    <Container maxWidth={'xl'}>
                    <Stack direction="row"
                           justifyContent="space-between"
                           alignItems="center"
                           width={"100%"}
                           height={'100%'}
                           spacing={2}>
                        <Card sx={{width: '50%', height: '36px', background: '#F272281A', borderRadius: "12px"}}>
                            <StatusDotDetail style={{backgroundColor: '#23AD00'}}/>
                            <Stack direction="row"
                                   justifyContent="space-between"
                                   alignItems="center"
                                   width={"100%"}
                                   height={'100%'}
                                   px={'10px'}
                                   spacing={2}>
                                <Typography variant="subtitle1" mb={0} color={"black"}>Normal</Typography>
                                <Typography variant="subtitle1" mb={0}
                                            color={"primary"}>{orders.data.totalNormalOrders}</Typography>
                            </Stack>


                        </Card>
                        <Card sx={{width: '50%', height: '36px', background: '#F272281A', borderRadius: "12px"}}>
                            <StatusDotDetail style={{backgroundColor: '#FF546A'}}/>
                            <Stack direction="row"
                                   justifyContent="space-between"
                                   alignItems="center"
                                   width={"100%"}
                                   height={'100%'}
                                   px={'10px'}
                                   spacing={2}>
                                <Typography variant="subtitle1" mb={0} color={"black"}>Express</Typography>
                                <Typography variant="subtitle1" mb={0}
                                            color={"primary"}>{orders.data.totalExpressOrders}</Typography>
                            </Stack>

                        </Card>

                    </Stack>
                    </Container>
                </OrderTotalStyle>

            }

        </Card>
    );
}

export default OrderTotal;