import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL_BACK,
});



axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },  (err) => {
        const errorConfig = err.config;
        if (err.response) {
            if (err.response.status === 401 && err.response.data.message === 'Unauthorized' && !errorConfig._retry) {

                return new Promise((resolve, reject) => {
                    errorConfig._retry = true;
                    const uninterceptedAxiosInstance = axios.create({
                        baseURL: process.env.REACT_APP_URL_BACK,

                    });

                    uninterceptedAxiosInstance.post("/auth/refreshToken", {
                        "refresh_token": localStorage.getItem("refreshToken"),
                    },{
                        auth:{
                            username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
                            password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
                        },
                    }).then((rs) => {
                        localStorage.setItem("accessToken", rs.data.access_token);
                        localStorage.setItem("refreshToken", rs.data.refresh_token);
                        axiosInstance.defaults.headers.common.Authorization = `Bearer ${rs.data.access_token}`;

                        errorConfig.__isRetryRequest = true;
                        errorConfig.headers.Authorization = `Bearer ${rs.data.access_token}`
                        resolve(axiosInstance(errorConfig));
                    }).catch((error) => {
                            reject(error);
                        }
                    )
                })
            } else {
                return Promise.reject(err);
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;
