import { createSlice } from "@reduxjs/toolkit";
// utils
// import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// eslint-disable-next-line no-empty-pattern
export const {} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = ["producto 1", "producto 2"];
      dispatch(slice.actions.getProductsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
