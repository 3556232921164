// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
// components
import SearchBar from "./SearchBar";
import OrderCard from "./OrderCard";
import {useState} from "react";
import * as React from "react";
import OrderTotal from "./orders/OrderTotal";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const HeaderStyle = styled("div")(({ theme }) => ({
    position: "fixed",
    width: "100%",
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
    backgroundColor: alpha(theme.palette.grey["200"], 0.6),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    zIndex: 1000,
    [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const Header = ({setLoading,setExpandedId}) => {
    const [search, setSearch] = useState("");

    return (
        <HeaderStyle>
            <Container maxWidth={"xl"}>
                <SearchBar search={search} setLoading={setLoading} setSearch={setSearch} setExpandedId={setExpandedId}/>
            </Container>
        </HeaderStyle>
    );
};

// ----------------------------------------------------------------------

const SEARCH_BAR = 74.13;

const OrdersContainerStyle = styled("div")(({ theme }) => ({
    paddingTop: SEARCH_BAR,
}));

const BasicCard = ({setLoading,loading }) => {

    const [expandedId, setExpandedId] = React.useState(-1);

    return (
        <Box position="relative">
            <Header setLoading={setLoading} setExpandedId={setExpandedId} />
            <OrdersContainerStyle>
                <Container maxWidth={"xl"}>
                    <OrderCard setLoading={setLoading} expandedId={expandedId} loading={loading} setExpandedId={setExpandedId}  />
                </Container>
            </OrdersContainerStyle>
            <OrderTotal/>
        </Box>
    );
};

export default BasicCard;