import {useState} from "react";
// components
import Page from "../../components/Page";
import BasicCard from "../../components/BasicCard";
import LoadingScreenPages from "../../components/LoadingScreenPages";

// ----------------------------------------------------------------------

export default function Orders() {
    const [loading, setLoading] = useState(false);
    return (
        <Page title="Órdenes" id="pageId">
            <LoadingScreenPages loading={loading}/>
            <BasicCard
                setLoading={setLoading} loading={loading}/>
        </Page>
    );
}