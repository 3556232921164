import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton } from '@mui/material';
// hooks
import { useLocation } from 'react-router-dom';

import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useState} from "react";
import {PATH_DASHBOARD} from "../../routes/paths";


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 40;
const APPBAR_DESKTOP = 40;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.grey["200"], 0.6),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE +"px !important",
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

const TypographyStyledNavbar = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    paddingRight:'0px'
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const location = useLocation()
  const [titleApp,setTitleApp] = useState("");

  React.useEffect(() => {
    switch (location.pathname) {
      case PATH_DASHBOARD.general.app:
        setTitleApp("Órdenes");
        break;
      case PATH_DASHBOARD.general.queryStock:
        setTitleApp("Consulta de stock");
        break;
      default:
        setTitleApp("Home");
        break;
    }
  }, [location, setTitleApp])

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >

      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <TypographyStyledNavbar variant="h5" width={'100%'} pr={'40px'} textAlign={'center'} color={'black'}>{titleApp}</TypographyStyledNavbar>

        <Box sx={{ flexGrow: 1 }} />


        {/* ----------------- */}
      </ToolbarStyle>
    </RootStyle>
  );
}
