import React, {useEffect, useState} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import {
    Alert,
    Dialog,
    DialogContent,
    DialogContentText,
    FormControl, Icon,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import axios from "../../../utils/axios";

import {LoadingButton} from "@mui/lab";
import {MIconButton} from "../../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import {useSnackbar} from "notistack";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";


const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 8; // change this to your preference.

const DialogStoresBrands = ({open, setOpen, isFirstLogin}) => {

    const [errorSelect, setErrorSelect] = useState(false);
    const [brands, setBrands] = useState(null);
    const [brand, setBrand] = useState("");
    const navigate = useNavigate();

    const [store, setStore] = useState("");
    const [stores, setStores] = useState(null);
    const [disabledStore,setDisabledStore] = useState(true);
    const [disabledButton,setDisabledButton] = useState(true);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {login, user} = useAuth();
    const [isLoading, setLoading] = useState(true);

    const handleCloseDialog = () => {
        setOpen(false);
        setBrand('');
        setStore('');
        setDisabledStore(true);
        setDisabledButton(true);
        setErrorSelect(false);
        setLoading(false);
    };
    useEffect(() => {
        const fetchData = async () => {
            if (open) {
                setLoading(true);
                await getBrands();
                if (!isFirstLogin) {
                    await fillUserBrandStore();
                }

            }
        };

        fetchData();
    }, [open]);

    useEffect(() =>{
        if(user != null && user.userPreferredLocation != null )
        {
            fillUserBrandStore();
        }
        else{
            setLoading(false);
        }
    },[user])

    const fillUserBrandStore = async ()=>{
        let storeRequest;
        if(!isFirstLogin)
        {
            storeRequest = await axios.get('/komax/getStoreByIdStore/'+user.location.idKomax);
        }
        else{
             storeRequest = await axios.get('/komax/getStoreByIdStore/'+user.userPreferredLocation);
        }
        const data = storeRequest.data
        if(data != null &&  data !== "")
        {
            setBrand(data.brand.idBrand);
            setDisabledStore(false);
            const responseStores = await axios.get('/komax/getStoreByIdBrand/'+data.brand.idBrand)
            setStores(responseStores.data);
            setStore(data.idKomax);
            setDisabledButton(false);
            setLoading(false);
        }

    }

    const getBrands  = async () =>{
        const brandsRequest = await axios.get('/komax/getBrands/');
        setBrands(brandsRequest.data);
    }
    const submitHandlerDialog = async (event) => {
        const locationCopy = [...stores];
        let locationObject = locationCopy.filter(value => value.idKomax.toString() === store.toString());
        setErrorSelect(false)
        if(isFirstLogin)
        {
            await login(user, locationObject);
            enqueueSnackbar("Inicio de sesión exitoso", {
                variant: "success",
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                    </MIconButton>
                ),
            });
        }
        else {
            localStorage.setItem("location",JSON.stringify(locationObject[0]));
            user.location = locationObject[0];
            navigate("/");
        }
    };

    const handleChangeBrand = async (event) => {
        if (event.target.value != null) {
            setErrorSelect(false)
        } else {
            setErrorSelect(true)
        }
        setBrand(event.target.value);
        const responseStores = await axios.get('/komax/getStoreByIdBrand/'+event.target.value)
        setStores(responseStores.data);
        setDisabledStore(false);
        setDisabledButton(true);
        setStore("");
    };

    const handleChangeStore = (event) => {
        if (event.target.value != null) {
            setErrorSelect(false)
        } else {
            setErrorSelect(true)
        }
        setDisabledButton(false);
        setStore(event.target.value);
    };
    return (
        <Dialog open={open} fullWidth={true} onClose={handleCloseDialog}>
            <DialogContent >
                {isLoading ? <Box sx={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></Box> : 
                <>
                <DialogContentText sx={{display: 'flex', justifyContent: 'center'}}>
                    Selecciona tu marca
                </DialogContentText>
                {errorSelect && <Alert severity="error">Debe seleccionar una marca!</Alert>}

                <FormControl variant="outlined" sx={{mt: 1, minWidth: '100%'}} size={"small"}>
                    <InputLabel id="brand-select-label">Marca</InputLabel>
                    <Select
                        labelId="brand-select-standard-label"
                        id="brand-select-standard"
                        value={brand}
                        required={true}
                        fullWidth={true}
                        onChange={handleChangeBrand}
                        label="Marca"
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxHeight: {
                                        xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                                        sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP
                                    },
                                    width: 250
                                }
                            }
                        }}
                        error={errorSelect}
                    >
                      { (brands != null && brands.length > 0 ? brands.map(l => {
                          return <MenuItem key={l.idbrand} value={l.idbrand}>{l.name}</MenuItem>
                      }) : (
                        <MenuItem key={'null'} value={""}>
                            No hay Marcas disponibles
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                <DialogContentText sx={{display: 'flex',mt:2, justifyContent: 'center'}}>
                    Selecciona tu tienda
                </DialogContentText>
                <FormControl variant="outlined" sx={{mt: 1, minWidth: '100%'}} size={"small"}>
                    <InputLabel id="store-select-label">Tienda</InputLabel>
                    <Select
                            labelId="store-select-label"
                            id="store-select-standard"
                            value={store}
                            required={true}
                            fullWidth={true}
                            disabled={disabledStore}
                            onChange={handleChangeStore}
                            label="Tienda"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: {
                                            xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                                            sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP
                                        },
                                        width: 250
                                    }
                                }
                            }}
                            error={errorSelect}
                    >
                        {stores != null && stores.length > 0 ? (
                            stores.map(l => (
                                <MenuItem key={l.idKomax} value={l.idKomax}>
                                    {l.nameShort}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem key={'null'} value={""}>
                                No hay tiendas disponibles
                            </MenuItem>
                        )}
                    </Select>

                    </FormControl>


                    <LoadingButton
                        fullWidth
                        size="medium"
                        disabled={disabledButton}
                        sx={{mt: 2}}
                        onClick={submitHandlerDialog}
                        variant="contained">
                        Ingresar
                    </LoadingButton>
                    </>
                }
            </DialogContent>
        </Dialog>
    );
};

export default DialogStoresBrands;