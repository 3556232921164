import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 60, height: 50, ...sx }}>
      <Box
        component="img"
        //src="/static/img/komax-isotipo.png"
        src="/static/img/AXO_logo_negro.png"
        style={{ width: "100%", height: "100%" }}
      />
    </Box>
  );
}
