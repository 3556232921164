import React, {useEffect} from 'react';
import {styled, useTheme} from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";
import {
    Box,
    FormControl, Icon, InputLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, MenuItem, Select,
    Stack, TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Typography from "@mui/material/Typography";
import SvgIconStyle from "../SvgIconStyle";
import StickyHeadTable from "./StickyHeadTable";
import {LoadingButton} from "@mui/lab";
import axios from "../../utils/axios";
import {formatData} from "../../utils/functions";
import {MIconButton} from "../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";
import {useSnackbar} from "notistack";
import useGlobVars from "../../hooks/useGlobalVars";

const ListItemStyle = styled(ListItemButton)(({theme}) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    textTransform: "capitalize",
    paddingLeft: '0px',
    color: theme.palette.text.secondary,
    "&:before": {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: "none",
        position: "absolute",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: '2px',
});

const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 8; // change this to your preference.

const KLIPER_IMAGE = "https://komax-files.s3.amazonaws.com/kliper_placeholder.jpg";
const NOT_FOUND_IMAGE = '/static/img/producto-no-encontrado.jfif';

const DetailQueryStock = ({product, setProduct, setRenderValue, setLoading, hideTable, setHideTable}) => {
    const theme = useTheme();

    const {user} = useAuth();

    const [stockLocations, setStockLocations] = React.useState([]);
    //State Style
    const [style, setStyle] = React.useState('');
    //State Color
    const [colors, setColors] = React.useState(['NFLE4','NFLE5'])
    const [color, setColor] = React.useState([]);
    const [disabledColor, setDisabledColor] = React.useState(false);
    //State Size
    const [sizes, setSizes] = React.useState([])
    const [size, setSize] = React.useState("");
    const [disabledSize, setDisabledSize] = React.useState(true);
    //State  stock button
    const [disabledStock, setDisabledStock] = React.useState(true);
    const [disabledLabel, setDisabledLabel] = React.useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {variables} = useGlobVars();


    useEffect(()=>{
        setStyle(product.sku.split('_')[0]);
        handleSetColors();
        //eslint-disable-next-line
    },[]);

    const handleSetColors = async ()=>{
        setLoading(true);
        let filtros = [
            {
                name: "rut_user",
                data: user.userRut
            },
            {
                name: "store_id",
                data: user.location.idKomax + ""
            }
        ];
        const filtrosResponse = formatData(filtros);
        try{
            const response = await axios.get('querystock/searchColorAvailableBySku/' + product.sku + filtrosResponse);
            if(response.status === 200)
            {
                const colors = response.data.data.Colores;
                const colorsOrded = colors.sort((a,b) => (a.desc_color < b.desc_color ? -1 : 1))
                setColors(colorsOrded);

            }
        }catch (e) {
            enqueueSnackbar("Error al buscar colores del producto", {
                variant: "error",
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                    </MIconButton>
                ),
            });
            setDisabledColor(true);
            setDisabledSize(true);
            setColors([]);
        }
        setLoading(false);
    }


    const handleChangeColor =  async (event) => {
        setLoading(true);
        let filtros = [
            {
                name: "rut_user",
                data: user.userRut
            },
            {
                name: "store_id",
                data: user.location.idKomax + ""
            }
        ];
        const filtrosResponse = formatData(filtros);
        try{
            const newColor = event.target.value;
            const sku = `${style}_${newColor}_${product.sku.split("_")[2]}`;
            const response = await axios.get('querystock/searchTallaAvailableBySku/' + sku  + filtrosResponse);
            if(response.status === 200)
            {
                const sizes = response.data.data.Tallas;
                setSizes(sizes);
                setColor(newColor);
                setDisabledSize(false);
            }
        }catch (e) {
            enqueueSnackbar("Error al buscar tallas del producto", {
                variant: "error",
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                    </MIconButton>
                ),
            });
            setDisabledSize(true);
            setSize([]);
        }
        setSize('')
        setDisabledStock(true);
        setLoading(false);
    };
    const handleChangeSize = (event) => {
        setSize(event.target.value);
        setDisabledStock(false);
    };
    const handleQueryStockLocations = async () =>{
        setLoading(true);
        let filtros = [
            {
                name: "rut_user",
                data: user.userRut
            },
            {
                name: "store_id",
                data: user.location.idKomax + ""
            }
        ];
        try {
            const sku = style + "_" + color + "_" + size;
            const response = await axios.get('querystock/searchStockAvaibleBySku/' + sku + formatData(filtros));

            //Give information about the locations
            if(response.status === 200)
            {
                let newProduct = product;

                let stockLocations = response.data.data;
                if(stockLocations.length > 0)
                {
                    stockLocations = stockLocations
                        .filter(s => !(s.available_qty === 0))
                        .sort((a,b) => (a.available_qty > b.available_qty ? -1 : 1))
                }
                try {
                    const responseImage = await axios.get("images/searchImagesBySku/"+ sku + formatData(filtros));
                    if(responseImage.status === 200)
                    {

                        newProduct.ImageURL = null;
                        setProduct(newProduct);
                        newProduct.ImageURL = responseImage.data.data.products[0].url;
                        if(newProduct.ImageURL === KLIPER_IMAGE)
                        {
                            newProduct.ImageURL = NOT_FOUND_IMAGE
                        }
                    }
                    else{
                        newProduct.ImageURL = NOT_FOUND_IMAGE
                    }
                }catch (e) {
                    newProduct.ImageURL = NOT_FOUND_IMAGE
                }
                setStockLocations(stockLocations);
                setHideTable(false);
                setDisabledLabel(true);
                newProduct.sku = sku;
                setProduct(newProduct);
            }
            else if(response.status === 204)
            {
                enqueueSnackbar("Sin información del producto", {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill}/>
                        </MIconButton>
                    ),
                });
                setColor('');
                setHideTable(true);
                setSize('');
                setDisabledSize(true);
                setDisabledStock(true);
            }
        } catch (e) {
            enqueueSnackbar("Error al buscar el producto", {
                variant: "error",
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill}/>
                    </MIconButton>
                ),
            });
            setHideTable(true);
            setColor('');
            setSize('');
            setDisabledSize(true);
            setDisabledStock(true);
        }
        setLoading(false);

    }
    return (
        <Box>
            {product != null ? <Card sx={{ p: theme.spacing(2), paddingBottom: '10px'}}>
                <List disablePadding>
                    <ListItemStyle onClick={() => {
                        setRenderValue(1);
                        setHideTable(true);
                        setDisabledLabel(false);
                    }}>
                        <ListItemIconStyle>
                            <SvgIconStyle
                                src={`/static/icons/navbar/ic_reply.svg`}
                                sx={{width: "100%", height: "100%", marginRight: '2px'}}
                            />
                        </ListItemIconStyle>
                        <ListItemText sx={{marginTop: '3px !important'}} disableTypography primary={"Volver"}/>

                    </ListItemStyle>
                </List>
                <Stack direction="row" spacing={2} sx={{paddingBottom: '10px'}}>
                    <Box  sx={{
                        width: '60%',
                        height: '140px',
                        maxWidth: {xs: 120, md: 120},
                        minWidth: {xs: 120, md: 120},


                    }}>
                        <LazyLoadImage
                            height= {'100%'}
                            width= {'100%'}
                            src={product.ImageURL == null ? NOT_FOUND_IMAGE : product.ImageURL}
                        />
                    </Box>

                    <Box display="flex" alignItems="center">
                        <div>
                            <Typography sx={{pb:'3px',display: 'block'}} variant={!disabledLabel ? 'caption' : 'subtitule1'}>Marca: {product.Desc_Brand}</Typography>
                            <Typography sx={{pb:'3px',display: 'block'}} variant={!disabledLabel ? 'caption' : 'subtitule1'}>SKU: {product.sku}</Typography>
                            <Typography  sx={{pb:'3px',display: !disabledLabel ? 'block' : 'none'}} variant="caption">Tienda: {user.location.name}</Typography>
                            <Typography  sx={{pb:'3px',display: !disabledLabel ? 'block' : 'none'}} variant="subtitle1">Stock Disponible: {product.stockAvailability}</Typography>
                        </div>

                    </Box>
                </Stack>
                <StickyHeadTable hideTable={hideTable} stockLocations={stockLocations}/>

                <Stack spacing={2}  sx={{paddingBottom: '10px'}}>
                    <FormControl sx={{ mt: 1, minWidth: 80 }} size="small">
                        <TextField value={style}  InputProps={{readOnly: true}} id="outlined-basic" label="Estilo" size="small" fullWidth variant="outlined" />

                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                        <InputLabel id="select-color-label">Color</InputLabel>
                        <Select
                            labelId="select-color-label"
                            id="select-color-input"
                            value={color}
                            onChange={handleChangeColor}
                            disabled={disabledColor}
                            fullWidth
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: {
                                            xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                                            sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP
                                        }
                                    }
                                }
                            }}
                            label="Color"
                        >
                            {colors.length > 0 ? colors.map((c,index) => {
                                return <MenuItem key={c.cod_color + index} value={c.cod_color}>{c.desc_color}</MenuItem>
                            }):false}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                        <InputLabel id="select-talla-label">Talla</InputLabel>
                        <Select
                            labelId="select-talla-label"
                            id="select-talla-input"
                            value={size}
                            disabled={disabledSize}
                            onChange={handleChangeSize}
                            fullWidth
                            label="Talla"
                        >
                            {sizes.length > 0 ?sizes.map((s,index) => {
                                return <MenuItem key={s.cod_size + index} value={s.cod_size}>{s.desc_size}</MenuItem>
                            }): false}
                        </Select>
                    </FormControl>
                </Stack>

                <Stack direction="row" spacing={2} pt={2}
                       justifyContent={"space-around"}>
                    <LoadingButton
                        size="medium"
                        color={'primary'}
                        onClick={handleQueryStockLocations}
                        disabled={disabledStock}
                        variant="contained">
                        Consultar Stock
                    </LoadingButton>

                </Stack>
            </Card>: false}

        </Box>
    );
};

export default DetailQueryStock;
