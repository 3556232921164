import React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import {CheckCircle} from "@mui/icons-material";
import useCollapseDrawer from "../../hooks/useCollapseDrawer";
import useAuth from "../../hooks/useAuth";

const DRAWER_WIDTH = 279;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled(Box)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.grey["200"], 0.6),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    }
}));
function Footer() {
    const { isCollapse } = useCollapseDrawer();
    const {user} = useAuth();
    return (

        <RootStyle sx={{
            ...(isCollapse && {
                width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
            }),
            height: '38px',
            pt: '5px'
        }}>
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} sx={{width: '100%',
                pl: '15px',
                pr: '27px',}}  spacing={0}>
                <Typography variant="subtitle2" sx={{display: "flex", alignItems: 'center', color: '#23AD00'}}>
                    <CheckCircle color={'#23AD00'}/> {user?.location.name}
                </Typography>
                <Typography variant="subtitle2" sx={{display: "flex", alignItems: 'center'}}> {user?.displayName}</Typography>
            </Stack>
        </RootStyle>
    );
}

export default Footer;