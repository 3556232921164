import { useContext } from 'react';
import {GlobalVariableContext} from "../contexts/GlobalVariableContext";
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
// import { AuthContext } from '../contexts/Auth0Context';

// ----------------------------------------------------------------------

const useGlobVars = () => useContext(GlobalVariableContext);

export default useGlobVars;
