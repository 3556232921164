import * as React from 'react';
import {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {LoadingButton} from "@mui/lab";
import {Alert, AlertTitle, Box, Stack} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import {Virtuoso} from 'react-virtuoso'
import useAuth from "../hooks/useAuth";
import {getOrdersBy} from "../redux/slices/orders";
import {useDispatch, useSelector} from "../redux/store";
import OrderDetail from "./orders/OrderDetail";
import axios from "../utils/axios";
import DialogOrderBarCode from "./orders/modal/DialogOrderBarCode";
import {stopCamera} from "../redux/slices/camera";
import {getTypeDelivery} from "../utils/functions";


const StatusDot = styled("span")(() => ({
    width: 5,
    display: 'block',
    position: 'absolute',
    top: 15,
    height: 47,
    borderRadius: 6
}));

function OrderCard({setLoading, expandedId, setExpandedId, loading}) {
    const dispatch = useDispatch();
    const [products, setProducts] = useState(null);
    const [product, setProduct] = useState(null);
    const [openModalBarCode, setOpenModalBarCode] = useState(false);
    const [errorDetail, setErrorDetail] = useState(false);
    const [barCode, setBarCode] = useState("");
    const [value, setValue] = useState(0);
    const [orderSelected, setOrderSelected] = useState(null);


    const theme = useTheme();
    const {user} = useAuth();
    const {orders, error} = useSelector((state) => {
        return state.orders
    });
    const {camera} = useSelector((state) => {
        return state.camera;
    });

    useEffect(() => {
        initialData();
        //eslint-disable-next-line
    }, [])

    useEffect(()=>{

    },[])

    const initialData = async () => {
        setLoading(true);
        let obj = [
            {
                name: "rut_user",
                data: user.userRut,
            },
        ];
        try {
            await dispatch(getOrdersBy(user.location.idKomax, obj));
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
        if(camera != null)
        {
            await dispatch(stopCamera())
        }
    }
    const handleExpandClick = async (index, element) => {
        setProducts(null);
        if (index === expandedId) {
            setOrderSelected(null);
            setExpandedId(-1);
        } else {
            setExpandedId(index)
            let obj = [
                {
                    name: "store_id",
                    data: user.location.idKomax,
                },
                {
                    name: "rut_user",
                    data: user.userRut,
                },
            ];
            try {
                setErrorDetail(false);
                const response = await axios.get("orders/detail/" + element.orderNumber + formatData(obj))
                if (response.status === 200) {
                    setProducts(response.data.data);
                    setOrderSelected(element);
                } else {
                    setProducts([])
                    setOrderSelected(null);
                }
            } catch (e) {
                setErrorDetail(true);
                setOrderSelected(null);
            }

        }
    };

    const formatData = (filtros) => {
        let string = "";
        filtros.forEach((element) => {
            if (element.data) {
                let simbol = string === "" ? "?" : "&";
                string = string + simbol + element.name + "=" + element.data;
            }
        });
        return string;
    };

    const calculateBody = () =>{
        let bodyHeight = document.getElementById("root").offsetHeight;
        return bodyHeight - 40 - 69.13 - 38 - 66;
    }
    const colorExpand = (index, element) => expandedId === index ? theme.palette.common.black : element.pickingState === 'pendiente de proceso en POS' ?  theme.palette.success.main :theme.palette.primary;
    return (
        <div>
            {orders == null || orders.length === 0 || orders.data === null || orders.data.orderList.length === 0 ?
                !loading ?
                    <Card sx={{maxWidth: '100%', mb: 2}}>
                        {error != null && error !== false ? <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                Ha ocurrido un error al cargar — <strong>órdenes!</strong>
                            </Alert> :
                            <Typography variant="h5" textAlign={"center"} my={1}
                                        color={"black"}>{orders != null && orders.data != null && orders.data.mensaje != null ? orders.data.mensaje : 'Te encuentras al día!'} </Typography>
                        }
                    </Card> : false
                :
                <div>
                    <Box sx={{height: calculateBody(), mb: '5px'}}>
                        <Virtuoso
                            style={{height: '100%'}}
                            totalCount={orders.data.orderList.length}
                            data={orders.data.orderList}
                            itemContent={(index, element) =>
                                <Card sx={{maxWidth: '100%', mb: 1}} key={index}>
                                    <StatusDot
                                        style={{backgroundColor: element.typeOfOrder === 1 ? '#FF546A' : '#23AD00'}}/>
                                    <CardContent sx={{paddingY: '8px !important', paddingRight: '8px !important', paddingLeft: "13px !important"}}>
                                        <Stack direction="row" spacing={1} justifyContent="space-between"
                                               alignItems="center">
                                            <Box>
                                                <Typography variant="subtitle1">Orden
                                                    N° {element.orderNumber}</Typography>
                                                <Typography variant="subtitle2">Fecha de entrega: {element.commitmentDate}</Typography>
                                                <Typography variant="subtitle2">Tipo entrega: {getTypeDelivery(element.typeOfOrder)}</Typography>
                                            </Box>
                                            <LoadingButton
                                                size="small"
                                                color={expandedId === index ? "inherit" :  element.pickingState === 'pendiente de proceso en POS' ? 'inherit': "primary"}
                                                sx={{
                                                    backgroundColor: colorExpand(index,element),
                                                    "&:hover": {backgroundColor: colorExpand(index,element)}
                                                }}
                                                onClick={() => handleExpandClick(index, element)}
                                                variant="contained">
                                                {expandedId === index ? 'Volver' : element.pickingState === 'pendiente de proceso en POS' ? 'Reservado': 'Detalle'}
                                            </LoadingButton>
                                        </Stack>
                                    </CardContent>
                                    <Collapse in={expandedId === index} timeout="auto" unmountOnExit>
                                        <OrderDetail errorDetail={errorDetail} products={products}
                                                     setProduct = {setProduct} setValue={setValue}
                                                     setOpenModalBarCode={setOpenModalBarCode}/>
                                    </Collapse>
                                </Card>
                            }
                        >

                        </Virtuoso>
                    </Box>

                    <DialogOrderBarCode
                        setOpenModalBarCode={setOpenModalBarCode}
                                        openModalBarCode={openModalBarCode}
                                        setBarCode={setBarCode}
                                        barCode={barCode}
                                        product={product}
                                        products={products}
                                        setProducts={setProducts}
                                        setProduct={setProduct}
                                        setLoading={setLoading}
                                        value={value}
                                        setValue={setValue}
                                        orderSelected={orderSelected}
                                        setExpandedId={setExpandedId}
                                        setOrderSelected={setOrderSelected}
                                        initialData={initialData}
                    />
                </div>
            }


        </div>
    );
}

export default OrderCard;