export const formatData = (filtros) => {
    let string = "";
    filtros.forEach((element) => {
        if (element.data) {
            let simbol = string === "" ? "?" : "&";
            string = string + simbol + element.name + "=" + element.data;
        }
    });
    return string;
};

export const getTypeDelivery = (idType) =>{
    if(idType === 1){
        return "Express";
    }
    else if(idType === 2)
    {
        return "Delivery";
    }
    else if(idType === 3)
    {
        return "Sucursal Chilexpress";
    }
    else if(idType === 4)
    {
        return "Retiro En Tienda";
    }
    else{
        return "No identificado";
    }
}
export const getStatusOrder = (statusOrder) =>{
    if(statusOrder === "Open"){
        return "Abierta";
    }
    else if(statusOrder === "pendiente de proceso en POS")
    {
        return "Pendiente POS";
    }
    else{
        return "No identificado";
    }
}
