import React, { useState} from 'react';
import {Alert, Box, Button, Icon, Stack, Tab, Tabs, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {styled, useTheme} from "@mui/material/styles";
import QueryStockCamera from "./QueryStockCamera";
import {useDispatch, useSelector} from "../../redux/store";
import {startCamera, stopCamera} from "../../redux/slices/camera";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import {formatData} from "../../utils/functions";
import {useSnackbar} from "notistack";
import {MIconButton} from "../@material-extend";
import closeFill from "@iconify/icons-eva/close-fill";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            pt={2}
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            height={'100%'}

            {...other}
        >
            {value === index && (<>{children}</>
            )}
        </Box>
    );
}
const TextFieldStyled = styled(TextField)(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadiusSm,
    "& fieldset": {
        border: "none",
    },
}));
const displayAlert = "none";
const infoAlert = "error";
const messageAlert = "Código de barra incorrecto";

const QueryBarCodeStock = ({setRenderValue, setLoading, product, setProduct}) => {

    const [value, setValue] = useState(0);
    const [barCode, setBarCode] = useState('');
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const theme = useTheme();
    const {user} = useAuth();

    const dispatch = useDispatch();
    const {camera} = useSelector((state) => {
        return state.camera;
    });
    const handleChange =  async (event, newValue) => {
        if(newValue === 1)
        {
            await dispatch(stopCamera());
        }
        else{
            await dispatch(startCamera(camera));
            setBarCode('');
        }
        setValue(newValue);
    };
    const onChangeBarCode = (value) => {
        setBarCode(value)
    }
    const searchProductByBarCode = async () =>{
        setLoading(true);
        let filtros = [
            {
                name: "rut_user",
                data: user.userRut
            },
            {
                name: "store_id",
                data: user.location.idKomax+""
            }
        ];

        try{
            const filtrosResponse = formatData(filtros);
            const productResponse =  await axios.get('querystock/productByBarCode/'+barCode+ filtrosResponse);
            //TODO() ACA VA CONSULTA DE COLORES INPUT MANUAL
            setProduct(productResponse.data.data);
            setLoading(false);
            setRenderValue(2);

        }catch (e) {
            setLoading(false);
            if(e.response.status === 404)
            {
                enqueueSnackbar("Producto no encontrado", {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill}/>
                        </MIconButton>
                    ),
                });
            }
            else{
                enqueueSnackbar("Error en la búsqueda de producto", {
                    variant: "error",
                    action: (key) => (
                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill}/>
                        </MIconButton>
                    ),
                });
            }

        }
    }
    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth">
                <Tab label="Escáner"/>
                <Tab label="Ingreso Manual"/>
            </Tabs>
            <TabPanel value={value} index={0} dir={theme.direction}>
                <Stack spacing={1} sx={{p:2}}>

                <Typography textAlign={'center'} pb={2}>Enfoque el código de barra del producto</Typography>
                <QueryStockCamera setRenderValue={setRenderValue}  setProduct={setProduct} setLoading={setLoading}/>
                </Stack>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction} >
                <Stack spacing={1} sx={{p:2}}>
                    <Typography textAlign={'center'} pb={2}>Ingrese el código de barra del producto</Typography>
                    <TextFieldStyled
                        label="Código de barra"
                        type='tel'
                        id="outlined-start-adornment"
                        value={barCode}
                        onChange={(e) => {
                            onChangeBarCode(e.target.value.replace(/[^0-9]/g, ''))
                        }}
                        sx={{pb: '12px'}}
                        inputProps={{
                            maxLength: 13,
                        }}
                        variant="standard"
                    />
                    <Alert severity={infoAlert} sx={{display: displayAlert}}>{messageAlert}</Alert>
                    <Stack direction="row" justifyContent={"space-around"} alignItems={"center"} spacing={2}>
                        <Button sx={{width: '50px', minWidth: '160px'}} color={'primary'} disabled={barCode.length === 0} variant="contained" onClick={searchProductByBarCode}>Consulta Stock</Button>
                    </Stack>
                </Stack>
            </TabPanel>

        </Box>

    );
};

export default QueryBarCodeStock;
