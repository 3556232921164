import { createSlice } from "@reduxjs/toolkit";
// utils
// import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isStarted: false,
  camera: null
};

const slice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    // START CAMERA
    startCamera(state,action) {
      state.isStarted = true;
      state.camera = action.payload;
    },
    //STOP CAMERA
    stopCamera(state, action) {
      state.isStarted = false;
      state.camera.reset();
      state.camera = null;
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
// eslint-disable-next-line no-empty-pattern
export const {} = slice.actions;

// ----------------------------------------------------------------------
export function startCamera(camera) {
  return async (dispatch) =>{
    dispatch(slice.actions.startCamera(camera));
  }
}
export function stopCamera(){
  return async (dispatch) =>{
    dispatch(slice.actions.stopCamera());
  }
}
