import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
// redux
import {store, persistor} from './redux/store';
// contexts
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
import {AuthProvider} from './contexts/JWTContext';
// components
import LoadingScreen from './components/LoadingScreen';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {GlobalVariableProvider} from "./contexts/GlobalVariableContext";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <HelmetProvider>
        <GlobalVariableProvider>
        <ReduxProvider store={store}>
            <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
                <CollapseDrawerProvider>
                    <BrowserRouter>
                        <AuthProvider>
                            <App/>
                        </AuthProvider>
                    </BrowserRouter>
                </CollapseDrawerProvider>
            </PersistGate>
        </ReduxProvider>
        </GlobalVariableProvider>
    </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();